import { FC, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useApiRequest from "@mbs-dev/api-request";
import { ErrorNotify, notify } from '@helpers/Toastify';
import { formHelper, FormInput, HeaderContainer, SubmitButton, loadingHelper } from '@helpers/Index';
import { ReactHelmet, apiUrl } from '@helpers/Helpers';
import { initLogin } from '@helpers/FormikHelper';
import { forgotPasswordSchema } from '@helpers/YupHelper';
import Layout from '../../layouts/Layout';
import './LoginStyle.css'


const ForgotPassword: FC = () => {

  // Global
  const navigate = useNavigate();
  const { apiRequest } = useApiRequest();
  const user_token = localStorage.getItem('cds_user_token');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user_token) {
      navigate('/my-account');
    }
  }, [user_token]);


  const formik = formHelper.useFormik({
    initialValues: initLogin,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('email', values.email);

        const response = await apiRequest({
          route: `${apiUrl}/forgot-password`,
          method: 'POST',
          data: formData
        });

        if (response.status === 200) {
          setIsLoading(false)
          if (response.data.status === true) {
            notify(response.data.message);
          }
          else if (response.data.status === false) {
            ErrorNotify(response.data.message);
          }
        }

      } catch (error) {
        ErrorNotify("Aucun d'utilisateur trouvé avec l'adresse e-mail !");
      }
    },
  });


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <Layout>
      <ReactHelmet title='Mot de passe oublié' />
      <HeaderContainer
        className="fw-bold fs-1 mt-5"
        title='Mot de passe oublié'
      />
      <div className="container d-flex-center pt-5 mb-5">
        <form onSubmit={handleSubmit} className='d-flex-center'>
          <div className="login-form">
            <div className="row d-flex-start">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                <FormInput
                  label="Email"
                  spanValue="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  className={touched.email && errors.email ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <Link to='/register' className='nav-link clr-dark ff-varela-round fw-600 blue-hover'>Vous n'avez pas de compte ? Signup</Link>
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-5 d-flex-start">
                {isLoading ?
                  <loadingHelper.DottedLoading />
                  :
                  <SubmitButton
                    className="mt-1 px-2rem py-14px"
                    btnLabel="Envoyé"
                  />
                }

              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ForgotPassword;