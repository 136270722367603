import React, { useState, memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useShoppingCart } from '@contexts/ShoppingCartContext';
import useApiRequest from "@mbs-dev/api-request";
import { PackProps } from '@helpers/Props';
import { apiUrl, packImgUrl } from '@helpers/Helpers';
import { HeaderContainer, hooks, SubmitButton } from '@helpers/Index';
import ReviewForm from '@components/Reviews/ReviewForm';
import ShareButtons from '@components/ShareButtons/ShareButtons';
import Modal from '@components/DetailsProductModal/ModalContent';
import ProductCard from '@components/ProductCard/ProductCard';
import Icons from '@helpers/IconsHelper';
import './PackDetailsStyle.css'
import Layout from '@layouts/Layout';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';

const PackDetails: React.FC = () => {

    const { slug } = useParams();
    const { windowWidth } = useWindowWidthContext()
    const [cardItemQuantity, setCardItemQuantity] = useState(1);
    const { addToCart } = useShoppingCart()
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const { apiRequest } = useApiRequest();
    const [pack, setPack] = useState<PackProps>();

    const { productIds, isProductIds } = hooks.useProductIds();


    const openModal = (product: any) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    // const pack = useMemo(() => packs.find(item => item.slug === slug), [packs, slug]);

    const fetchProductData = async () => {
        try {
            const response = await apiRequest({
                route: `${apiUrl}/pack-slug/${slug}`,
                method: 'GET',
            });

            if (response.status === 200) {
                setPack(response.data)
            }

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchProductData();
    }, [])


    const increment = () => {
        setCardItemQuantity(prevItem => prevItem + 1);
    };

    const decrement = () => {
        if (cardItemQuantity > 1) {
            setCardItemQuantity(prevItem => prevItem - 1);
        }
    };


    if (!pack) {
        return null
    }


    return (

        <Layout>
            <Helmet>
                <title>Chakib Drugstore - {pack.title}</title>
            </Helmet>

            <div className="pack-details mt-5 pt-5 mb-5">
                <div className="pack-constainesr container d-flexd justify-content-cdenter ">
                    <div className="row pack-details-content d-flex justify-content-around">

                        <div className="col-xl-5 col-lg-5 col-md-6 col-sm-10 col-8 mt- mb-5">
                            <div className={`pack-image-gallery d-flex justify-content-center`}>
                                {
                                    <img src={`${packImgUrl}/${pack.image}`}
                                        alt={pack.title}
                                        className="shadow rounded img-fluid pack-details-image d-flex justify-content-center" />
                                }
                            </div>

                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-2 ps-3 ps-sm-1">
                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 col-10 mt-2 ps-3 ps-sm-1"> */}
                            <div className="pack-details-info">
                                <h1 className='pack-details-title'>{pack.title}</h1>
                                <p className="pack-details-prix mt-3 mb-5">{pack.sellingPrice} DH</p>
                                <hr className='hr-tag' />

                                <div className="pack-details-total">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-6 pack-details-total-text">
                                            Prix total :
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-6 pack-details-total-price">
                                            {
                                                cardItemQuantity > 0 ?
                                                    `${pack.sellingPrice * Number(cardItemQuantity)} DH`
                                                    :
                                                    `${pack.sellingPrice} DH`
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="row align-items-center mt-5">
                                    <div className={`col-md-4 col-lg-4 col-xl-4 col-12 ${windowWidth < 578 ? 'mb-2' : ''}`}>
                                        <div className="radius-10px shadow-1 py-12px mx-10px max-w-13rem d-flex-center text-center">
                                            <span className="fs-20px" onClick={() => decrement()}>
                                                <Icons.HiMinusSm className='shopping-cart-item-quantity-icon fs-30px' />
                                            </span>
                                            <span className="px-25px fs-18px">{cardItemQuantity}</span>
                                            <span className="fs-20px" onClick={() => increment()}>
                                                <Icons.BiPlus className='shopping-cart-item-quantity-icon fs-30px' />
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-md-8 col-lg-8 col-xl-8 col-12">
                                        <button onClick={() => addToCart(pack.uniqId, pack.sellingPrice, 'pack', cardItemQuantity)} type="submit" className="d-none btn addtocard-btn">
                                            <Icons.FiShoppingCart className='modal-card-icon' />Ajouter au panier
                                        </button>
                                        <SubmitButton
                                            btnLabel="Ajouter au panier"
                                            content={<Icons.FiShoppingCart className='addtocart-icon' />}
                                            onClick={() => addToCart(pack.uniqId, pack.sellingPrice, 'pack', cardItemQuantity)}
                                            className="mt-1 px-2rem py-14px" />
                                    </div>

                                </div>

                                <hr className='hr-tag' />

                                <div className="pack-details-desc-parent">
                                    <p className='pack-details-desc' dangerouslySetInnerHTML={{ __html: pack.description.replaceAll('\\n', "<br>") }}></p>
                                </div>

                                <hr className='hr-tag' />
                                <ShareButtons product={pack} entity={'pack'} />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container mb-5 pb-5 d-non desc-section">
                    <hr className='hr-tag' />

                    <div className="productss populare-products mb-6-rem">
                        <div className="container">
                            <HeaderContainer className="fw-bold fs-3" title="Liste Des Produits" />
                            <div className="row justify-content-center">
                                {pack.packItems.length > 0 && pack.packItems.map((item: any) => (
                                    <ProductCard key={item.product.id} item={item.product} openModal={openModal} productIdsObj={{ productIds, isProductIds }} />
                                ))}
                            </div>
                        </div>
                    </div>
                    {showModal && <Modal show={showModal} onClose={closeModal} product={selectedProduct} />}

                    <div className="row mt-5 d-non">
                        <hr className='hr-tag' />
                        <div className="col-xl-5 col-lg-5 col-md-12 col-12">
                            <ReviewForm productId={pack.id} isPack={true} />
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12 col-12 d-none">
                            <div className="col-12 d-flex justify-content-center">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {pack.description && (
                                        <li className="nav-item" role="presentation">
                                            <button className="btn desc-section-title active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">DESCRIPTION</button>
                                        </li>
                                    )}
                                    <li className="nav-item d-none" role="presentation">
                                        <button className="btn desc-section-title" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">INFORMATIONS COMPLÉMENTAIRES</button>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-8 col-xl-8 col-md-8 col-10 desc-col">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active"
                                        id="pills-home"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabIndex={0}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: pack.description.replaceAll('\\n', "<br>") }} className='pack-description'></div>

                                    </div>
                                    <div className="tab-pane fade d-none" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex voluptate laudantium molestiae vero officiis suscipit nisi sunt doloribus dolor odit?
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default memo(PackDetails);
