import { FC, useState, useEffect, useRef, Fragment } from 'react'
import { Link } from 'react-router-dom'
import useApiRequest from "@mbs-dev/api-request";
import { ProductProps } from '@helpers/Props'
import { NavLink, apiUrl, categorieImgUrl, handleImageLinkDrage, marqueImgUrl, marqueLogoUrl } from '@helpers/Helpers'
import {  hooks, logo } from '@helpers/Index'
import Icons from '@helpers/IconsHelper'
import OffCanvas from '../OffCanvas/OffCanvas'
import SearchBox from '../SearchBox/SearchBox'
import './NavBarStyle.css'
import { useShoppingCart } from '@contexts/ShoppingCartContext'
import { useCategoriesContext } from '@contexts/CategoriesContext'
import { useMarquesContext } from '@contexts/MarquesContext'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'

let timeout: NodeJS.Timeout
let marquetTimeout: NodeJS.Timeout
let categorietTimeout: NodeJS.Timeout

const Navbar: FC = () => {

  useEffect(() => {
    return () => {
      clearTimeout(timeout)
      clearTimeout(marquetTimeout)
      clearTimeout(categorietTimeout)
    }
  }, [])

  // Global
  const { apiRequest } = useApiRequest();
  const { productIds, isProductIds } = hooks.useProductIds()

  // Contexts
  const { windowWidth } = useWindowWidthContext()
  const { marques } = useMarquesContext()
  const { categories } = useCategoriesContext()
  const { updateCartItemsWithPromotions } = useShoppingCart()

  // States
  const [searchBoxVisible, setSearchBoxVisible] = useState(false)
  const [ShoppingCartBoxVisible, setShoppingCartBoxVisible] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isMarqueDropdownOpen, setIsMarqueDropdownOpen] = useState(false)
  const [isCategorieDropdownOpen, setIsCategorieDropdownOpen] = useState(false)
  const [CategorieIndex, setCategorieIndex] = useState(0)
  const [products, setProducts] = useState<ProductProps[]>([])
  const [textValue, setTextValue] = useState('')

  // Use Click Outside
  const elementRef = useRef<HTMLDivElement>(null)
  const blockRef = useRef<HTMLDivElement>(null)

  // OffCanvas close
  const closeMarqueBtnRef = useRef<HTMLButtonElement | null>(null)
  const closeCategorieBtnRef = useRef<HTMLButtonElement | null>(null)
  const closeNavbarBtnRef = useRef<HTMLButtonElement | null>(null)

  const closeOffCanvasMarque = () => {
    if (closeMarqueBtnRef.current) {
      closeMarqueBtnRef.current.click()
    }
  }

  const closeOffCanvasCategorie = () => {
    if (closeCategorieBtnRef.current) {
      closeCategorieBtnRef.current.click()
    }
  }

  useEffect(() => {
    updateCartItemsWithPromotions()
  }, [ShoppingCartBoxVisible])

  const handleShoppingCartBoxClose = () => {
    setShoppingCartBoxVisible(false)
  }

  hooks.useClickOutside(elementRef, handleShoppingCartBoxClose, blockRef)

  const handleDropdownMouseEnter = () => {
    if (windowWidth > 992) {
      clearTimeout(timeout)
      setIsDropdownOpen(true)
    }
  }


  const handleDropdownMouseLeave = () => {
    if (windowWidth > 992) {
      timeout = setTimeout(() => {
        setIsDropdownOpen(false)
      }, 200)
    }
  }

  const handleMarqueDropdownMouseEnter = () => {
    clearTimeout(marquetTimeout)
    setIsMarqueDropdownOpen(true)
  }

  const handleMarqueDropdownMouseLeave = () => {
    marquetTimeout = setTimeout(() => {
      setIsMarqueDropdownOpen(false)
    }, 200)
  }

  const handleCategorieDropdownMouseEnter = (index: number) => {
    clearTimeout(categorietTimeout)
    setIsCategorieDropdownOpen(true)
    setCategorieIndex(index)
  }

  const handleCategorieDropdownMouseLeave = (index: number) => {
    categorietTimeout = setTimeout(() => {
      setIsCategorieDropdownOpen(false)
      setCategorieIndex(index)
    }, 200)
  }

  const searchProducts = async (text: string) => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/products.json?title=${text}&disponibilite=enstock`,
        method: 'GET',
      })

      if (response.status === 200) {
        setProducts(response.data)
      } else {
      }

    } catch (error) {
    }
  }

  useEffect(() => {
    if (!searchBoxVisible) {
      setProducts([])
      setTextValue('')
    }
  }, [searchBoxVisible])

  const handleSearchIconClick = () => {
    setSearchBoxVisible(!searchBoxVisible)
  }

  const linkContent =
    <>
      <NavLink to="/boutique/best-seller" label="Best Sellers" />
      <NavLink to="/boutique/latest" label="Nouveautés" />
      <NavLink to="/boutique/promotion" label="Promotions" />
      <NavLink to="/boutique" label="Boutique" />
    </>

  const marquesContent =
    <>
      <div className="row o-hidden" >
        {marques.map((item, index) => (
          <Fragment key={item.slug}>
            <div className={`col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 d-flex-${windowWidth < 578 ? 'center' : 'center'} flex-wrap`} onClick={() => setIsDropdownOpen(false)} >
              <div className="m-2 marque-image-container">
                <Link to={`/boutique/marque/${item.slug}`} onClick={closeOffCanvasMarque} >
                  <img
                    src={`${marqueLogoUrl}/${item?.logo}`}
                    className={`brand-carousel-logo w-${index === 0 || index === 1 ? '75' : '80'}`}
                    alt={item?.title}
                    onDragStart={handleImageLinkDrage}
                  />
                  <img
                    src={`${marqueImgUrl}/${item.image}`}
                    alt={item.title}
                    className='nav-item-marque-image' />

                  <div className="brand-carousel-overlay d-flex-center c-pointer text-center">
                  </div>
                  <div className="brand-carousel-overlay-before d-flex-center c-pointer text-center">
                  </div>
                </Link>
              </div>

              <div className={`nav-item-marque-title ${windowWidth < 578 ? 'text-center' : 'text-center ps-0'}`}>
                <Link className='bm-link' to={`/boutique/marque/${item.slug}`} >
                  <p className='ff-varela-round fw-600 m-0 c-pointer'>{item.title}</p>
                </Link>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </>

  const categoriesContent =
    <>
      <div className="row o-hidden">
        {categories.map((item, index) => (
          <Fragment key={item.slug}>
            {
              index < 8 ?
                <div className={`col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 d-flex-center flex-wrap`} onClick={() => setIsDropdownOpen(false)} key={item.slug}>
                  <div className="m-2 categorie-image-container">
                    <Link to={`/boutique/categorie/${item.slug}`} onClick={closeOffCanvasCategorie}>
                      <img
                        src={`${categorieImgUrl}/${item.image}`}
                        alt={item.title}
                        className='nav-item-image' />
                    </Link>
                  </div>

                  <div className="nav-item-categorie-title text-center">
                    <Link className='bm-link' to={`/boutique/categorie/${item.slug}`} >
                      <p className='ff-varela-round fw-600 m-0 c-pointer'>{item.title}</p>
                    </Link>
                  </div>
                </div>
                : ''
            }
          </Fragment>
        ))}
      </div>
    </>

  const navbarContent =
    <div className="row justify-content-center ai-center" >
      <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
        <div className="container-fluid d-flex-start">
          <div className="w-100" id="">
            <Link to="/">
              <img src={logo} alt="chakibdrugstore" className='offcanvas-logo mb-5' />
            </Link>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {linkContent}

              {/* Start Marque */}
              <li className="nav-item ps-0 dropdown"
                onMouseEnter={handleMarqueDropdownMouseEnter}
                onMouseLeave={handleMarqueDropdownMouseLeave}
                onClick={() => setIsDropdownOpen(false)}>
                <a
                  className="nav-link navbar-link dropdownstoggle" href="/"
                  data-bs-toggle="offcanvas" data-bs-target="#offcanvasmarque" aria-controls="offcanvasmarque">
                  NOS MARQUES
                </a>

                <OffCanvas
                  position='end'
                  content={marquesContent}
                  label='Nos Marques'
                  id='offcanvasmarque'
                  closeBtnRef={closeMarqueBtnRef}
                />

              </li>
              {/* End Marque */}

              {windowWidth < 992 ?
                <>
                  {/* Start Categorie */}
                  <li
                    className={`nav-item ps-0 dropdown`}
                    onMouseEnter={handleDropdownMouseEnter}
                    onMouseLeave={handleDropdownMouseLeave} >
                    <a
                      className="nav-link navbar-link dropdownstoggle text-upper"
                      href="/"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvascategorie"
                      aria-controls="offcanvascategorie" >
                      NOS Catégories
                    </a>

                    <OffCanvas
                      position='end'
                      content={categoriesContent}
                      label='Nos Categories'
                      id='offcanvascategorie'
                      closeBtnRef={closeCategorieBtnRef}
                    />

                  </li>
                  {/* End Categorie */}
                </>
                :
                null
              }

            </ul>
          </div>
        </div>
      </div>
    </div>


  return (
    <>
      <header className={`header-nav w-100 d-flex-start pt-${windowWidth < 992 ? '2' : ''} ${windowWidth < 992 ? 'ox-hidden' : ''}`}>
        <div className={`containerr w-100 mx-${windowWidth > 1320 ? '5' : windowWidth > 1140 ? '3' : windowWidth > 578 ? '3' : 0} top-container`}>
          <div className="row justify-content-center ai-center">
            <div className={`${windowWidth > 992 ? 'col-xxl-5 col-xl-5' : 'col-xxl-7 col-xl-7'} col-lg-5 col-md-11 col-sm-11 col-10`}>
              <nav className="navbar navbar-expand-lg navbar-right">
                <div className="container-fluid d-flex-start">

                  <div className="responsive-icon">
                    <button className={`navbar-toggler ${windowWidth < 992 ? 'collapsed' : ''}`}
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasnavbar"
                      aria-controls="offcanvasnavbar"
                      aria-expanded="false"
                      aria-label="Toggle navigation">

                      <span className="navbar-toggler-icon"></span>
                    </button>
                  </div>

                  <div className={`w-100 collapse navbar-collapse ${windowWidth < 992 ? 'd-none' : ''}`} id="navbarNavDropdown">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      {linkContent}

                      {/* Start Marque */}
                      <li className="nav-item ps-0 dropdown"
                        onMouseEnter={handleMarqueDropdownMouseEnter}
                        onMouseLeave={handleMarqueDropdownMouseLeave}
                        onClick={() => setIsDropdownOpen(false)}>

                        {windowWidth > 992 ?
                          <a className="nav-link navbar-link  dropdown-toggle"
                            href="/" id="navbarDropdownMenuLink"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            aria-label="marques"
                          >
                            NOS MARQUES
                          </a>
                          :
                          null
                        }

                        {windowWidth > 992
                          ?
                          <ul className={`dropdown-menu dropdown-menu-marque ${windowWidth > 520 ? isMarqueDropdownOpen ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                            {marquesContent}
                          </ul>
                          :
                          null
                        }

                      </li>
                      {/* End Marque */}

                      {windowWidth < 992 ?
                        <>
                          {/* Start Categorie */}
                          <li
                            className={`nav-item ps-0 dropdown`}
                            onMouseEnter={handleDropdownMouseEnter}
                            onMouseLeave={handleDropdownMouseLeave} >
                            {windowWidth > 992
                              ?
                              <a
                                className="nav-link navbar-link dropdown-toggle text-upper"
                                href="/"
                                id="navbarDropdownMenuLink"
                                role="button"
                                data-bs-toggle="dropdown" aria-expanded="false"
                                aria-label="categories"
                              >
                                NOS Catégories
                              </a>
                              :
                              null
                            }

                            {windowWidth > 992
                              ?
                              <ul className={`dropdown-menu dropdown-menu-categorie ${windowWidth > 520 || true ? isDropdownOpen ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                                {categoriesContent}
                              </ul>
                              :
                              null
                            }

                          </li>
                          {/* End Categorie */}
                        </>
                        :
                        null
                      }
                    </ul>
                  </div>
                </div>
              </nav>
            </div>

            <div className={`${windowWidth > 992 ? 'col-xxl-2 col-xl-2' : 'col-xxl-2 col-xl-2'} col-lg-2 col-md-12 col-sm-12 col-12 d-flex-${windowWidth > 1250 ? 'center' : windowWidth > 992 ? 'center' : 'start'}`}>
              <div className={`navbar-brand m-logo ${windowWidth < 992 ? 'ms3' : 'ms-0me-4'}`}>
                <Link to="/">
                  <img src={logo} alt="chakibdrugstore" className='chakibdrugstore logo-chakibdrug' />
                </Link>
              </div>
              {windowWidth < 992 ?
                <div className="d-flex-end w-100 search-contact">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 w-100 d-contents">
                    <li className='text-end'>
                      <div className={`${windowWidth > 578 ? 'pe-8px pt-0' : 'pe-14px'}`} onClick={handleSearchIconClick}>
                        <Link to='/contact' className='bm-link'>
                          <Icons.MdContactMail className="navbar-icon ms-sm-2 navbar-icon-search" />
                        </Link>
                      </div>
                    </li>
                    <li className='text-end'>
                      <div className="pe-4 pt-1" onClick={handleSearchIconClick}>
                        <Icons.BsSearch className="navbar-icon ms-sm-2 navbar-icon-search" />
                      </div>
                    </li>
                  </ul>
                </div>
                :
                null}
            </div>

            <div className={`${windowWidth > 992 ? 'col-xxl-5 col-xl-5 col-lg-5' : 'col-xxl-3 col-xl-3'} ${windowWidth < 992 ? 'd-none' : ''} ${windowWidth < 1140 ? 'col-categorie' : ''} col-md-6 col-sm-8 col-5  text-${windowWidth > 578 ? 'end' : 'center'} d-flex justify-content-end align-items-center`}>
              <nav className="navbar navbar-expand-lg navbar-right">
                <div className="container-fluid d-flex-start">
                  <div className="w-100 collapse navbar-collapse" id="navbarNavDropdown">

                    {/* Start Categories */}
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      {windowWidth > 992 ?
                        categories.map((item, index) => (
                          <li className="nav-item px-0 dropdown"
                            key={item.slug}
                            onMouseEnter={() => handleCategorieDropdownMouseEnter(index)}
                            onMouseLeave={() => handleCategorieDropdownMouseLeave(index)}>
                            {/* <Link to={`/boutique/categorie/${item.slug}`} className="text-upper px-1  nav-link navbar-link  dropdowntoggle" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"> */}
                            <Link to={`/boutique/categorie/${item.slug}`} className="text-upper px-1  nav-link navbar-link  dropdowntoggle" id="navbarDropdownMenuLink" >
                              {item.title}
                            </Link>
                            {item.subCategory.length > 0 ?
                              <ul className={`dropdown-menu ${windowWidth > 520 ? isCategorieDropdownOpen && CategorieIndex === index ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                                {item.subCategory.map((sub) => (
                                  <li key={sub.id}>
                                    <Link className="dropdown-item text-cap" to={`/boutique/categorie/${item.slug}/${sub.slug}`}>
                                      {sub.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                              :
                              null
                            }
                          </li>
                        ))
                        :
                        null
                      }

                    </ul>
                    {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-none">
                      <li>
                        <div className={`p${windowWidth > 1400 ? 's' : 'e'}-4 pt-0`} onClick={handleSearchIconClick}>
                          <Icons.BsSearch className="navbar-icon clr-pink fs-3 ms-sm-2 navbar-icon-search" />
                        </div>
                      </li>
                    </ul> */}

                    {/* End Categories */}
                  </div>

                </div>
              </nav>
            </div>
          </div>
        </div>

        {windowWidth < 992
          ?
          <OffCanvas
            position='start'
            content={navbarContent}
            label=''
            id='offcanvasnavbar'
            closeBtnRef={closeNavbarBtnRef}
          />
          :
          null
        }



      </header>

      {/* Start Search box */}
      <SearchBox
        searchBoxVisible={searchBoxVisible}
        textValue={textValue}
        products={products}
        productIds={productIds}
        isProductIds={isProductIds}
        position='nav'
        setTextValue={setTextValue}
        searchProducts={searchProducts}
      />
      {/* End Search box */}
    </>
  )
}

export default Navbar