import React from 'react';
import HeaderContainer from '@components/HeaderContainer/HeaderContainer';
import { Helmet } from 'react-helmet-async';
import Layout from '../../layouts/Layout';
import Icons from '@helpers/IconsHelper';

const TermsOfSale: React.FC = () => {

    return (
        <Layout>
            <Helmet>
                <title>Chakib Drugstore - Conditions Générales De Vente</title>
            </Helmet>
            <HeaderContainer
                className="ff-lato-bold fs-1 mt-5 "
                title='Conditions Générales De Vente'
            />

            <div className="container">
                <div className="row d-flex-start">
                    <div className="col-12 terms">
                        <p>
                            <p className='fw-bold me-2 mb-4'>AVERTISSEMENT</p>

                            <p>Vous venez de vous connecter sur le site https://chakibdrugstore.com de la Société <span className='website-name'>Chakib Drugstore</span></p>

                            <p>Vous devez lire attentivement les dispositions qui vont suivre car elles constituent un contact établissant les conditions générales des produits et services du catalogue ( ou de la boutique) électronique de la Société <span className='website-name'>Chakib Drugstore</span>.</p>

                            <p>Le « clic » que vous exécuterez après avoir rempli votre bon de commande constitue la validation de votre commande et vaudra acceptation irrévocable des présentes conditions générales.</p>

                            <p>En conséquence, vous ne pouvez commander de produits ou services que si vous acceptez les conditions prévues ci-dessous.</p>

                            <p>Les conditions générales de vente suivantes régissent l’ensemble des transactions établies sur le catalogue web https://chakibdrugstore.com. Toute commande passée sur ce site suppose du client son acceptation inconditionnelle et irrévocable de ces conditions.</p>

                            <p className='fw-bold me-2 mt-5'>Objet</p>

                            <p>
                                Le présent contrat est un contrat à distance qui a pour objet de définir les droits et obligations des parties dans le cadre de la vente des produits de la société <span className='website-name'>Chakib Drugstore</span>, sur Internet, par l’intermédiaire de la plateforme

                                https://chakibdrugstore.com un service et une marque déposée par <span className='website-name'>Chakib Drugstore</span>
                            </p>


                            <p className='fw-bold me-2'>Définitions</p>
                            <p>Dans la suite du contrat, chacune des expressions mentionnées ci-dessus s’entendra au sens de sa définition, à savoir :</p>

                            <p className='ps-4'><Icons.BsDot className='fs-3 adress-details-icon' />« Contrat à distance » : tout contrat concernant des biens ou services entre la Société <span className='website-name'>Chakib Drugstore</span> et un Consommateur dans le cadre d’un système de vente ou de prestations de service à distance organisé par la Société <span className='website-name'>Chakib Drugstore</span> qui, pour ce contrat, utilise exclusivement le réseau Internet jusqu’à la conclusion du contrat, y compris la conclusion du contrat elle-même.</p>
                            <p className='ps-4'><Icons.BsDot className='fs-3 adress-details-icon' />« Consommateur » : toute personne physique qui, dans le présent contrat, agit à des fins qui n’entrent pas dans le cadre de son activité professionnelle.</p>
                            <p className='ps-4'><Icons.BsDot className='fs-3 adress-details-icon' />« Bon de commande » :  document qui indique les caractéristiques des Produits commandés par le Consommateur et qui doit être signé de lui par « double clic » pour l’engager.</p>
                            <p className='ps-4'><Icons.BsDot className='fs-3 adress-details-icon' />« Commande » : acte par lequel le Consommateur s’engage à acheter des Produits et la société <span className='website-name'>Chakib Drugstore</span> à les livrer.</p>


                            <p className='fw-bold me-2'>Produit</p>

                            <p>Tous les produits présents dans le catalogue sont commercialisés jusqu’à épuisement des stocks. La société <span className='website-name'>Chakib Drugstore</span> se réserve le droit de retirer du catalogue un article, et ceci sans préavis, elle ne peut en aucun cas être tenu de dédommager ou d’annuler une commande suite  à l’impossibilité d’utiliser le produit acheté pour une raison d’incompatibilité avec le matériel déjà possédé par l’acheteur.</p>

                            <p className='fw-bold me-2'>Passer une commande</p>

                            <p>Après avoir trouver le ou les produits qui vous intéresse, cliquer sur le bouton ‘’commander’’, le produit est alors ajouté dans votre bon de commande.</p>
                            <p>Vous pouvez à tout instant modifier votre bon de commande et vous pouvez retourner au catalogue en appuyant sur poursuivre les achats.</p>

                            <p>Lorsque vous avez terminé vos achats, valider votre commande en cliquant sur le bouton de commande ‘’ payer’’.</p>

                            <p>En cas d’erreur de votre part, au moment de votre saisie sur le bon de commande, nous vous conseillons de nous adresser dans les 24 h une demande d’annulation par e-mail ; Après annulation de la commande par <span className='website-name'>Chakib Drugstore</span>, vous pouvez commander à nouveau.</p>



                            <p className='fw-bold me-2'>Prix</p>


                            <p>Les prix mentionnés sur le catalogue sont indiqués en dirham marocain hors taxe, sont valables au moment de leur consultation par le client et sont ceux en vigueur au moment de la commande.</p>

                            <p>la TVA est calculée dans le bon de commande en fonction du taux applicable au jour de la commande. Le bon de commande et les transactions se font en DH marocain.</p>

                            <p>Tout changement du taux sera automatiquement répercuté sur le prix des produits.</p>



                            <p className='fw-bold me-2'>Exécution de la commande</p>


                            <p>La commande sera exécutée au plus tard dans un délai de 7 jours à compter du jour suivant celui où le Consommateur a passé sa commande.</p>

                            <p>En cas d’indisponibilité du Produit commandé, le Consommateur en sera informé au plus tôt et aura la possibilité d’annuler sa commande.</p>

                            <p>Il aura alors l’indisponibilité du Produit commandé, le Consommateur en sera informé au plus tôt et aura la possibilité d’annuler sa commande.</p>

                            <p>Il aura alors le choix de demander soit le remboursement des sommes versées dans les trente jours au plus tard de leur versement, soit l’échange du Produit.</p>

                            <p className='fw-bold me-2'>Livraison</p>


                            <p>La livraison n’est pas assurée par <span className='website-name'>Chakib Drugstore</span>, le client récupèrera sa marchandise dans l’adresse de livraison indiqué sur son Bon de commande ou dans nos différents points de ventes.</p>

                            <p>Les Produits sont livrés à l’adresse indiquée par le Consommateur sur le Bon de commande. Le Consommateur est tenu de vérifier de l’emballage de la marchandise à la livraison et de signaler les dommages dus au transporteur sur le bon de livraison ainsi qu’à la Société <span className='website-name'>Chakib Drugstore</span> dans un délai d’un jour.</p>

                            <p>Le Consommateur peut, à sa demande, obtenir l’envoi d’une facture à l’adresse de livraison en validant l’option prévue à cet effet sur le Bon de commande.</p>

                            <p className='fw-bold me-2'>Combien coûte la livraison ?</p>


                            <p>Les frais de transport sont à la charge de l’acheteur,</p>
                            <p className='ps-4'><Icons.BsDot className='fs-3 adress-details-icon' /> Au Maroc et à l’étranger, ils sont basés sur les tarifs de Amana en envoi recommandé.</p>
                            <p className='ps-4'><Icons.BsDot className='fs-3 adress-details-icon' /> Nos tarifs s’entendent TTC et sont calculés sur la base du poids des produits.</p>
                            <p className='ps-4'><Icons.BsDot className='fs-3 adress-details-icon' /> Le coût du transport de votre livraison est indiqué sur votre facture.</p>
                            <p className='ps-4'><Icons.BsDot className='fs-3 adress-details-icon' /> Il diminue avec la quantité commandée. Le mieux est de regrouper vos commandes.</p>



                            <p className='fw-bold me-2'>Quels sont les délais de livraison ?</p>



                            <p>Votre commande est expédiée dans les 48 heures, sous réserve de stock disponible, après la réception de votre règlement.</p>
                            <p>Au Maroc et à l’étranger, les articles vous seront envoyés par poste en recommandé.</p>
                            <p>Les éventuels retards ne sauraient donner lieu ni à des dommages et intérêts, ni à annulation de commande, ni refus de prendre la livraison.</p>



                            <p className='fw-bold me-2'>Mode de paiement</p>


                            <p>Pour régler votre commande, vous choisissez le moyen de paiement parmi ceux proposés par <span className='website-name'>Chakib Drugstore</span> au niveau de la page de paiement.</p>



                            <p>Dans ce cas, la remise de la transaction pour débit de votre compte est effectuée dans la journée qui suit la date de la confirmation de livraison.</p>



                            <p>Vos paiements Multi-canaux sont sécurisés par le Centre Monétique Interbancaire (CMI) qui offre un service de paiement entièrement sécurisé.</p>



                            <p>Le Consommateur garantit la Société <span className='website-name'>Chakib Drugstore</span> qu’il dispose des autorisations éventuellement nécessaires pour utiliser le mode de paiement choisi par lui, lors de la validation du Bon de commande.</p>

                            <p>En cas de paiement par carte bancaire, les dispositions relatives à l’utilisation frauduleuse du moyen de paiement prévues dans les conventions conclues entre le Consommateur et l’émetteur de la carte entre la Société <span className='website-name'>Chakib Drugstore</span> et son établissement bancaire s’appliquent.</p>





                            <p className='fw-bold me-2'>Confidentialité des données</p>

                            <p>Les informations qui vous sont demandées sont nécessaires au traitement de votre commande et elles sont traitées de manière confidentielle.</p>
                            <p>Vous disposez d’un droit de rectification relatif aux données vous concernant.</p>
                            <p>Vous pouvez être amenés à recevoir des publicités de la part de <span className='website-name'>Chakib Drugstore</span> sauf si vous avez stipulé que vous refusiez l’utilisation de vos données nominatives à ses fins.</p>



                            <p className='fw-bold me-2'>Annuler une commande payée par carte bancaire</p>


                            <p className='fw-bold me-2'>Droit de rétractation</p>


                            <p>A compter de la date de livraison de votre commande, vous disposez d’un délai de 7 jours pour faire valoir votre droit de rétractation, et être intégralement remboursé. Les frais de renvoi des marchandises restant à votre charge. Cependant, seules les marchandises retournées en parfait état de revente, complètes et dans leur emballage d’origine (non-ouvert) pourront être remboursées.</p>





                            <p className='fw-bold me-2'>Garantie</p>


                            <p>Tous les produits vendus sur le catalogue sont garantis contre les défauts de fabrication et vice de matière. La durée de cette garantie est d’au moins une année (à partir de la date de facture). En cas de retour de matériel s’avérant non défectueux, des frais de transport et de manutention pourront être facturés au client. Cette garantie ne couvre pas les dommages résultant d’accidents, de mauvaise utilisation ou de négligence.</p>



                            <p className='fw-bold me-2'>Preuve des transactions payés par carte bancaire</p>


                            <p>Les données enregistrées par le CMI sur la plate-forme de paiement Multi-canal pour le compte de <span className='website-name'>Chakib Drugstore</span> constituent la preuve de l’ensemble des transactions commerciales passées entre vous et la société <span className='website-name'>Chakib Drugstore</span>.</p>





                            <p className='fw-bold me-2'>Force majeure</p>


                            <p><span className='website-name'>Chakib Drugstore</span> n’est tenu pour l’exécution de ses obligations que dans la mesure où aucun événement de force majeure ne vient les entraver.</p>
                        </p>
                    </div>
                </div>
            </div>


        </Layout>
    );
};

export default TermsOfSale;
