import { FC, Fragment, useEffect, useState } from 'react';
import { WishlistItem } from '@components/WishlistItem/WishlistItem';
import { useWishlist } from '@contexts/WishlistContext';
import { Link } from 'react-router-dom';
import { AuthUserProps } from '@helpers/Props';
import { ReactHelmet } from '@helpers/Helpers';
import { HeaderContainer,  emptyWishlist } from '@helpers/Index';
import Layout from '../../layouts/Layout';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import './Wishlist.css';

const Wishlist: FC = () => {

    const { wishlistItems, wishlistQuantity, updateWishlistItemsWithPromotions } = useWishlist()
    const { windowWidth } = useWindowWidthContext()

    const [authUser, setAuthUser] = useState<AuthUserProps | null>(null);
    const storedUser = localStorage.getItem('cds_user');

    useEffect(() => {
        if (storedUser) {
            setAuthUser(JSON.parse(storedUser));
        }
    }, []);

    useEffect(() => {
        updateWishlistItemsWithPromotions()
    }, [])

    return (
        <Layout>
            <ReactHelmet title='Wishlist' />

            <div className="wishlist-content d-flex justify-content-betdwen align-items-center">
                <div className="container product-cdontainer">
                    <HeaderContainer className='fw-bold fs-1 mt-5' title="Mes Favoris" />

                    {wishlistQuantity < 1 ?
                        <div className="row pt-5">
                            <div className="col-12 d-flex-center col-">
                                <img src={emptyWishlist} alt="empty wishlist" className='wishlist-empty-image' />
                            </div>
                            <div className="col-12 mt-3 d-flex-center col-">
                                <h3>Votre list des favoris est actuellement vide.</h3>
                            </div>
                            <div className="col-12 mt-3 d-flex-center col-">
                                <Link to='/boutique' className='nav-link back-to-boutique'>RETOUR À LA BOUTIQUE</Link>
                            </div>
                        </div>
                        :
                        <div className={`row ${windowWidth < 578 ? 'px-3' : ''}`}>
                            <div className="col-12 wishlist-items">
                                <div className="wishlist-items-list">
                                    {wishlistItems.map(item => (
                                        <Fragment key={item.ukey}>
                                            <WishlistItem key={item.ukey} itemKey={item.ukey} variantId={item.variant} {...item} isvariant={item.isvariant} price={item.price} />
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </Layout>
    )
}

export default Wishlist;