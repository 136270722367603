import React, { useCallback, useEffect, useState } from 'react';
import { apiUrl, logOut } from '@helpers/Helpers';
import { Helmet } from 'react-helmet-async';
import { AuthUserProps } from '@helpers/Props';
import { useNavigate } from 'react-router-dom';
import useApiRequest from "@mbs-dev/api-request";
import { UserDashboard } from '../UserDashboard/UserDashboard';
import { UpdateNotify, FrCustomeErrorNorify } from '@helpers/Toastify';
import { loadingHelper, formHelper, SubmitButton } from '@helpers/Index';
import { initUserAccount, initUserPassword } from '@helpers/FormikHelper';
import { userAccountSchema, userPasswordSchema } from '@helpers/YupHelper';
import './EditAccountStyle.css'


const Content: React.FC = () => {

    const { apiRequest } = useApiRequest();
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [isLoadingPass, setIsLoadingPass] = useState(false);
    const navigate = useNavigate();
    const [user, setUser] = useState<AuthUserProps | null>(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('cds_user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const fetchData = useCallback(async () => {
        try {
            if (user?.id) {
                const response = await apiRequest({
                    route: `${apiUrl}/user/id/${user.id}`,
                    method: 'GET',
                    requiresAuth: true,
                    token: `${localStorage.getItem('cds_user_token')}`
                });

                if (response.status === 200) {
                    await formik.setValues({
                        nom: response.data.nom ? response.data.nom : '',
                        prenom: response.data.prenom ? response.data.prenom : '',
                        telephone: response.data.telephone ? response.data.telephone : '',
                        whatsapp: response.data.whatsapp ? response.data.whatsapp : '',
                    });
                }
            }
        } catch (error) {

        }
    }, [user?.id]);

    useEffect(() => {
        fetchData()
    }, [user?.id])

    const formik =formHelper.useFormik({
        initialValues: initUserAccount,
        validationSchema: userAccountSchema,
        onSubmit: async (values) => {
            if (user?.id) {
                const fullName = `${values.nom} ${values.prenom}`
                const data = {
                    ...values, fullName: fullName
                }
                setIsLoadingInfo(true);
                const response = await apiRequest({
                    route: `${apiUrl}/users/${user?.id}`,
                    method: 'PUT',
                    data: data,
                    requiresAuth: true,
                    token: `${localStorage.getItem('cds_user_token')}`
                });
                if (response.status === 200) {
                    UpdateNotify('Votre compte est');
                    setIsLoadingInfo(false)
                } else {
                    FrCustomeErrorNorify()
                    setIsLoadingInfo(false)
                }
            }
        },
    });

    const formikPassword = formHelper.useFormik({
        initialValues: initUserPassword,
        validationSchema: userPasswordSchema,
        onSubmit: async (values) => {
            setIsLoadingPass(true);

            try {
                const formData = new FormData();
                formData.append('password', values.password);
                formData.append('newpassword', values.newpassword);
                formData.append('email', String(user?.email));

                const response = await apiRequest({
                    route: `${apiUrl}/edit-password`,
                    method: 'POST',
                    data: formData,
                    requiresAuth: true,
                    token: `${localStorage.getItem('cds_user_token')}`
                });

                if (response.status === 200) {
                    logOut();
                    navigate('/login')
                    UpdateNotify('Mot de passe est')
                    setIsLoadingPass(false)
                } else {
                    FrCustomeErrorNorify()
                    setIsLoadingInfo(false)
                }

            } catch (error) {
                FrCustomeErrorNorify()
                setIsLoadingInfo(false)
            }
        },
    });

    return (
        <>
            <Helmet>
                <title>Détails du compte</title>
            </Helmet>
            <div className="col-12 col-lg-7 col-md-7 col-xl-8 col-xxl-9 bm-ms-4">
                <div className="container contact-container d-flex-center margin-left">
                    <div className="edit-form">
                        <div className="row d-flex-start">
                            <form onSubmit={formik.handleSubmit} className='d-nonex'>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Nom"
                                        spanValue="Nom"
                                        name="nom"
                                        value={formik.values.nom}
                                        className={formik.touched.nom && formik.errors.nom ? "is-invalid" : ""}
                                        touched={formik.touched}
                                        errors={formik.errors}
                                        handleChange={formik.handleChange}
                                        handleBlur={formik.handleBlur}
                                        isRequired
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Prenom"
                                        spanValue="Prenom"
                                        name="prenom"
                                        value={formik.values.prenom}
                                        className={formik.touched.prenom && formik.errors.prenom ? "is-invalid" : ""}
                                        touched={formik.touched}
                                        errors={formik.errors}
                                        handleChange={formik.handleChange}
                                        handleBlur={formik.handleBlur}
                                        isRequired
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Telephone"
                                        spanValue="Telephone"
                                        name="telephone"
                                        value={formik.values.telephone}
                                        className={formik.touched.telephone && formik.errors.telephone ? "is-invalid" : ""}
                                        touched={formik.touched}
                                        errors={formik.errors}
                                        handleChange={formik.handleChange}
                                        handleBlur={formik.handleBlur}
                                        isRequired
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Whatsapp"
                                        spanValue="Whatsapp"
                                        name="whatsapp"
                                        value={formik.values.whatsapp}
                                        className={formik.touched.whatsapp && formik.errors.whatsapp ? "is-invalid" : ""}
                                        touched={formik.touched}
                                        errors={formik.errors}
                                        handleChange={formik.handleChange}
                                        handleBlur={formik.handleBlur}
                                        isRequired
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mt-5 d-flex-start">
                                    {isLoadingInfo ?
                                        <loadingHelper.DottedLoading />
                                        :
                                        <SubmitButton
                                            className="px-2rem py-14px"
                                            btnLabel="Enregistrer les modifications"
                                        />
                                    }
                                </div>
                            </form>

                            <form onSubmit={formikPassword.handleSubmit}>
                                <hr className='hr-tag mt-4' />
                                <h4 className='text-start mb-4 mt-2' >CHANGEMENT DE MOT DE PASSE</h4>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Mot de passe actuel"
                                        spanValue="Mot de passe actuel"
                                        name="password"
                                        type="password"
                                        value={formikPassword.values.password}
                                        className={formikPassword.touched.password && formikPassword.errors.password ? "is-invalid" : ""}
                                        touched={formikPassword.touched}
                                        errors={formikPassword.errors}
                                        handleChange={formikPassword.handleChange}
                                        handleBlur={formikPassword.handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Nouveau mot de passe"
                                        spanValue="Nouveau mot de passe"
                                        name="newpassword"
                                        type="password"
                                        value={formikPassword.values.newpassword}
                                        className={formikPassword.touched.newpassword && formikPassword.errors.newpassword ? "is-invalid" : ""}
                                        touched={formikPassword.touched}
                                        errors={formikPassword.errors}
                                        handleChange={formikPassword.handleChange}
                                        handleBlur={formikPassword.handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Confirmer le mot de passe"
                                        spanValue="Confirmer le mot de passe"
                                        name="confirmPassword"
                                        type="password"
                                        value={formikPassword.values.confirmPassword}
                                        className={formikPassword.touched.confirmPassword && formikPassword.errors.confirmPassword ? "is-invalid" : ""}
                                        touched={formikPassword.touched}
                                        errors={formikPassword.errors}
                                        handleChange={formikPassword.handleChange}
                                        handleBlur={formikPassword.handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mt-5 d-flex-start">
                                    {isLoadingPass ?
                                        <loadingHelper.DottedLoading />
                                        :
                                        <SubmitButton
                                            className="px-2rem py-14px"
                                            btnLabel="Modifier le mot de passe"
                                        />
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const EditAccount: React.FC = (props) => {
    return (
        <UserDashboard secondComponent={Content} />
    );
};

export { EditAccount };